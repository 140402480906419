import _ from "lodash";

export const getDefaultDraftYear = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
  
    // rollover to new draft year in July
    if (currentMonth > 6)
      return currentYear + 1;
  
    return currentYear;
  };

  export const getDefaultSeason = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    if (currentMonth < 8) return currentYear - 1;
    return currentYear;
  };

  export const getDefaultAnalyticsSeason = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    if (currentMonth < 9) return currentYear - 1;
    return currentYear;
  };

  export const getSeasons = (firstYear: number, lastYear: number, order?: string) => {
    const seasons = [];
    while (firstYear <= lastYear) {
      seasons.push(firstYear);
      firstYear++;
    }
    return _.orderBy(seasons, [(a) => a], [order == undefined ? "asc" : "desc"]);
  };
